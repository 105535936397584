<template>
  <b-card
    no-body
  >
    <div
      v-if="isLoading"
      class="my-3"
    >
      <custom-loading></custom-loading>
    </div>
    <div
      v-else
    >
      <v-grid
        ref="refGridSopExecutiveTotal"
        :source="source" 
        :columns="columns" 
        :columnTypes="pluginRevo"
        theme="material" 
        resize="true"
        autoSizeColumn="true"
        readonly="true"
        range="true"
        class="grid-container-sop-executive-total"
      ></v-grid>
    </div>
  </b-card>
</template>

<script>
import { 
  BCard, BButton, BCardBody, BFormGroup,
} from 'bootstrap-vue'
import { ref, getCurrentInstance, inject } from 'vue'
import VGrid, { VGridVueTemplate } from '@revolist/vue-datagrid'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import NumberColumnType from '@revolist/revogrid-column-numeral'
import FormatCellNumber1 from '../FormatCellNumber1.vue'
import gql from 'graphql-tag'
import ExecutiveAddRowModal1 from './ExecutiveAddRowModal1.vue'
import vSelect from 'vue-select'

export default {
  components: {
    BCard, 
    BButton, 
    BCardBody,
    BFormGroup,

    CustomLoading,
    ExecutiveAddRowModal1,
    VGrid,
    vSelect,
  },
  setup(props) {
    const source = ref([])
    const refGridSopExecutiveTotal = ref(null)
    const columns = ref([])

    // inject
    const yearSelected = inject("yearSelected")
    const isLoading = inject("isLoading")

    const pluginRevo = { 
      'numeric': new NumberColumnType('0,0'),
      'decimal': new NumberColumnType('0,0.0'),
      'percentage': new NumberColumnType('0,0[.]00 %'),
      'numeric_negative': new NumberColumnType('(0,0)'),
    }

    const monthArray = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ]

    const vm = getCurrentInstance().proxy

    const getStyleGrid = (prop, model, column) => {
      let id = source.value[0].id.toString()
      let yearId = parseInt(id.slice(0,4))
      let monthId = parseInt(id.slice(4)) - 1
      let validMonth = monthArray.slice(0, monthId)
      let validMonthStyle = monthArray.slice(0)

      if(model.bpu.length == 0) {
        return {
          "border-bottom": "3px solid #ebebeb",
        }
      }

      let customStyle = {
        "text-align": "right",
        "border-top": "3px solid #ebebeb",
        "border-right": "3px solid #ebebeb",
        "border-bottom": "3px solid #ebebeb",
        "border-left": "3px solid #ebebeb",
      }

      /* fila de porcentaje */
      if(column.prop == "year" & model.year == "") {
        return {
          
        }
      }
      
      /* year and bpu */

      /* COLOR DE LA TABLA EN CABECERA VERTICAL */
      if (column.prop == "year"
        || column.prop == "bpu") {
        delete customStyle["text-align"]
        delete customStyle["border-top"]
        customStyle["font-weight"] = "bold"
        customStyle["background"] = "#1e347b" /*#003366 color original*/ 
        customStyle["color"] = "white"

        if(column.prop == "year") {
          delete customStyle["border-right"]
        }

        if(column.prop == "bpu" & model.year != "") {
          delete customStyle["border-left"]
        }
      }
      
      /*end year and bpu */

      /* colocar color a los meses cerrados */
      if(validMonthStyle.includes(column.prop)) {
        delete customStyle["border-left"]
        delete customStyle["border-right"]
        delete customStyle["border-top"]
      }
      
      if (yearSelected.value == yearId) {
        if(validMonth.includes(column.prop.toLowerCase())) {
          customStyle["background"] = "#D9E1F2"
        }
      }

      if(column.prop == "total_anho") {
        delete customStyle["border-left"]
        delete customStyle["border-top"]
      }

      /* end colocar color a los meses cerrados */

      
      if(column.prop.toLowerCase() === "q1" 
        || column.prop.toLowerCase() === "q2" 
        || column.prop.toLowerCase() === "q3"
        || column.prop.toLowerCase() === "q4"
      ) {
        customStyle["background"] = "#BFBFBF"
        delete customStyle["border-left"]
        delete customStyle["border-right"]
        delete customStyle["border-top"]
        return customStyle
      }

      return customStyle
    }

    columns.value = [
      {
        "name": "TOTAL COMPANY",
        columnProperties: ({prop, model, data, column}) => {
          return {
            style: {
              color: 'white',
              "text-align": "center",
              "background": "#1e347b",
              "font-size": "30px",
            }
          };
        },
        "children": [
          {
            "name": "Year",
            "prop": "year",
            "size": 70,
            "autoSize": true,
            "readonly": true,
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
            columnProperties: ({prop, model, data, column}) => {
              return {
                style: {
                  color: 'transparent',
                  "border-bottom": "3px solid #ebebeb",
                }
              };
            },
          },
          {
            "name": "BPU",
            "prop": "bpu",
            "size": 180,
            "autoSize": true,
            "readonly": true,
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
            columnProperties: ({prop, model, data, column}) => {
              return {
                style: {
                  color: 'transparent',
                  "border-bottom": "3px solid #ebebeb",
                  "border-right": "3px solid #ebebeb",
                }
              };
            },
          },
          {
            "name": "Ene",
            "prop": "enero",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Feb",
            "prop": "febrero",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Mar",
            "prop": "marzo",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Q1",
            "prop": "q1",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header-quarter': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Abr",
            "prop": "abril",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "May",
            "prop": "mayo",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Jun",
            "prop": "junio",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Q2",
            "prop": "q2",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header-quarter': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Jul",
            "prop": "julio",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Ago",
            "prop": "agosto",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Sep",
            "prop": "septiembre",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Q3",
            "prop": "q3",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header-quarter': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Oct",
            "prop": "octubre",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Nov",
            "prop": "noviembre",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Dic",
            "prop": "diciembre",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Q4",
            "prop": "q4",
            "size": 70,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header-quarter': true
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
          {
            "name": "Total",
            "prop": "total_anho",
            "size": 90,
            "autoSize": true,
            cellTemplate: VGridVueTemplate(FormatCellNumber1),
            columnProperties: ({prop, model, data, column}) => {
              return {
                class: {
                  'revo-header': true
                },
                style: {
                  "border-right": "3px solid #ebebeb",
                }
              }
            },
            cellProperties: ({ prop, model, data, column }) => {
              return {
                style: getStyleGrid(prop, model, column)
              }
            },
          },
        ]
      },
    ]

    // subscription

    vm.$apollo.addSmartSubscription('getExecutiveTotal', {
      query: gql`
        subscription getExecutiveTotal($customWhere: json = null) {
          function_get_executive_total(args: {customWhere: $customWhere}) {
            year
            bpu
            enero
            febrero
            marzo
            q1
            abril
            mayo
            junio
            q2
            julio
            agosto
            septiembre
            q3
            octubre
            noviembre
            diciembre
            q4
            total_anho
            id
          }
        }
      `,
      variables(){
        return {
          customWhere: {
            "year":[yearSelected.value]
          }
        }
      },
      result ({data}) {
        isLoading.value = false
        source.value = data.function_get_executive_total

        // remove _typename
        for(let obj of source.value) {
          if (obj.__typename) delete obj.__typename
        }
      },
    })

    return {
      source,
      columns,
      isLoading,
      refGridSopExecutiveTotal,
      pluginRevo,
    }
  },
}
</script>

<style lang="scss">
.title-month {
  font-size: 35px;
  font-weight: bold;
}

.grid-container-sop-executive-total {
  width: 100%;
  height: 450px;
  font-family: "Segoe UI" !important;
  font-weight: bold;
  font-size: 15px;

  .temp-bg-range {
    display: block !important;
    position: absolute;
    pointer-events: none;
    z-index: 9;
    border: 1px solid #ff5e00;
    box-sizing: border-box;
  }

  .group-rgRow{
    margin-bottom: 20px;
  }

  .rgRow .disabled {
    background: none;
  }

  .rgHeaderCell.sortable:hover {
    background-color: #f1f1f1;
    color: rgb(0, 0, 0);
  }

  .header-rgRow:not(.group) {
    box-shadow: none;
  }

  revogr-header .header-rgRow.group {
    box-shadow: none;
  }

  revogr-data .rgRow  {
    box-shadow: none;
  }

  revogr-header .rgHeaderCell.focused-cell {
    color: black;
  }
}

/* we will explain what these classes do next! */
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
