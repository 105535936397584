<template>
  <b-card
  >
    <div style="width: 150px; font-size: 1.2rem;">
      <b-form-group
        label-for="Año"
        style="background-size: 2rem;"
      >Year
        <v-select
          v-model="yearSelected"
          :options="yearOptions"
          :reduce="val => val.value"
          input-id="executive-vselect-year"
          :clearable='clearableSelect'
          @input="changedSelectedYear"
        />
      </b-form-group>
    </div>
    
    <executive-visual-bpu-total1></executive-visual-bpu-total1>

    
    <b-tabs
      class="executive-visual-11-tabs"
      
    >
    <b-tab
        v-for="(bpu, index) in bpuValues"
        :key="index"
        :active="index === 0"
        
        >

        <template #title>
          <feather-icon
            icon="DatabaseIcon"
            size="15"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline" >{{bpu}}</span>
        </template>
        
        <executive-visual-bpu1 :bpuSelected="bpu"></executive-visual-bpu1>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import { 
  BCard, BTab, BTabs, BFormGroup,BDropdownDivider,BIconBrush
} from 'bootstrap-vue'
import { ref, getCurrentInstance, provide, computed } from 'vue'
import CustomLoading from '@/modules/common/CustomLoading.vue'
import vSelect from 'vue-select'
import ExecutiveVisualBpu1 from './ExecutiveVisualBpu1.vue'
import ExecutiveVisualBpuTotal1 from './ExecutiveVisualBpuTotal1.vue'
import { data } from 'vue-echarts'
import { props } from 'vue-prism-component'

export default {
  components: {
    BCard,
    BTab,
    BTabs,
    BFormGroup, BDropdownDivider,
    BIconBrush,
    ExecutiveVisualBpu1,
    ExecutiveVisualBpuTotal1,
    CustomLoading,
    vSelect,
  },
  setup(props) {
    const clearableSelect = ref(false)
    const yearSelected = ref()
    const yearOptions = ref()
    const isLoading = ref(true)

    // provide
    provide("yearSelected", yearSelected)
    provide("isLoading", isLoading)

    const bpuValues = ref([
      "Cosmetics",
      "Derma",
      "Health Care",
    ])
    

    const changedSelectedYear = () => {
      isLoading.value = true
    }

    const getCurrentYear = () => {
      return new Date().getFullYear()
    }

    const setYearOptions = () => {
      let currentYear = getCurrentYear()
      yearSelected.value = currentYear
      let nextYear = currentYear + 1
      yearOptions.value = [
        {"label": currentYear, "value": currentYear},
        {"label": nextYear, "value": nextYear}
      ]
    }
    setYearOptions()

    return {
      clearableSelect,
      yearSelected,
      yearOptions,
      bpuValues,

      changedSelectedYear,
    }
  },
}
</script>

<style lang="scss">

.tab-content > .tab-pane:not(.active),
.content > .pane:not(.active) {
  display: block !important;
  height: 0;
  overflow-y: hidden;
}

.executive-visual-11-tabs {
  .nav {
    li a {
      height: 50px;
      font-size: 18PX;
      margin-bottom: 1.5rem;
    }
  }
}

</style>
