<template>
 <div
    v-if="checkIfPercentage"
  >
     <!-- {{formatPercentage}}  -->
    <span :style="{ color: percentageColor }">{{ formatPercentage }}</span>
  </div>
  <div
    v-else
  >
    {{formatNumber}}
  </div>  
</template>

<script>
// your vue component
export default {
  // access any cell properties here
  props: ["rowIndex", "prop", "model"],
  name: "formatCellNumber",
  data() {
    return {
    };
  },
  computed: {
    checkIfPercentage: function () {
      return this.model.bpu.includes("%")
    },
    formatPercentage: function () {
      return Number(this.model[this.prop]).toLocaleString(undefined,{style: 'percent', minimumFractionDigits:1}); 
    },
    formatNumber: function () {
      if (this.model[this.prop] == "") return ""
      return Number(this.model[this.prop]).toLocaleString('en-US', {maximumFractionDigits: 0})
    },
    // Cambio colores de los porcentajes de toda las tablas de Executive.
    percentageColor: function () {
      const value = parseFloat(this.model[this.prop]);
      return value > 0 ? "green" : value < 0 ? "red" : "black";
    },
  },
};

</script>

<style scoped>
</style>